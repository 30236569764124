import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
  RouterOptions
} from 'vue-router';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/index.vue'),
  },
  {
    path: '/realTime',
    name: 'realTime',
    component: () => import('@/views/realTime/index.vue'),
  },
  {
    path: '/nenghao',
    name: 'nenghao',
    component: () => import('@/views/nenghao/index.vue'),
  },
  {
    path: '/nengxiao',
    name: 'nengxiao',
    component: () => import('@/views/nengxiao/index.vue'),
  },
  {
    path: '/taizhang',
    name: 'taizhang',
    component: () => import('@/views/taizhang/index.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/index.vue'),
  },
  {
    path: '/error',
    name: 'error',
    redirect: '/error/404',
    children: [
      {
        name: '404',
        path: '404',
        component: () => import('@/views/404/index.vue'),
        meta: { hidden: true },
      },
      {
        name: '403',
        path: '403',
        component: () => import('@/views/403/index.vue'),
        meta: { hidden: true },
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    redirect: '/error',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return {
      el: '#app',
      top: 0,
      left: 0,
      behavior: 'smooth',
    };
  },
} as RouterOptions);

// 路由未登录放行白名单
const routerNameWhiteList = ['login', 'dashboard', 'realTime', 'nenghao', 'nengxiao', 'taizhang', 'search'];
router.beforeEach(async (to:any, from:any, next) => {
  if (!store.getters.userToken && !routerNameWhiteList.includes(to.name)) {
    await store.dispatch('user/checkLogin');
  }
  const userType = store.getters.userType;
  if (to.meta.requireAuth && !to.meta.requireAuth.includes(userType)) {
    next('/error/403');
  }
  next();
});

export default router;
