import Axios from 'axios';
import { ElMessage } from 'element-plus';
import { showLoading, hideLoading } from './loading';
import { delCookie, getCookie } from '@/utils/cookie';
import store from '@/store';

const baseURL = '';

const axios = Axios.create({
  baseURL,
  timeout: 20000, // 请求超时 20s
  headers: {
    'token': 'token',
  }
});

// 前置拦截器（发起请求之前的拦截）
axios.interceptors.request.use(
  // TODO: 根据项目实际情况来对 response 做处理
  (response) => {
    if (!response.headers.noloading) {
      showLoading();
    }
    response.headers.token = getCookie("token");
    return response;
  },
  (error) => Promise.reject(error),
);

// 后置拦截器（获取到响应时的拦截）
axios.interceptors.response.use(
  // TODO: 根据项目实际情况来对 response 做处理
  (response) => {
    hideLoading();
    const { data } = response;
    return data;
  },
  (error) => {
    hideLoading();
    if (error.response && error.response.data) {
      const code = error.response.status;
      const msg = error.response.data.message;
      ElMessage.error(`错误码: ${code}, 提示信息: ${msg || '系统错误'}`);
      console.error('[Axios Error]', error.response);
    } else {
      ElMessage.error(`${error}`);
    }
    return Promise.reject(error);
  },
);

export default axios;
