import axios from '@/utils/axios';
// 平台公共
export const fileUpload = async({}, params:any) => {
    const { data } = await axios({
        url: '/api/file/upload',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        // 上传文件1分钟超时时间
        timeout: 60000
    });
    return data;
}
// 公证书校验
export const notarialFileCheck = async({}, params:any) => {
    const { data } = await axios({
        url: '/api/file/check',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        // 上传文件1分钟超时时间
        timeout: 60000
    });
    return data;
}
