/**
 * cookie处理工具函数
 */
 import cookies from 'js-cookie';

// 获取
 const getCookie = function(key: string) {
   const token = cookies.get(key) || '';
   return token;
 };
// 存入
const setCookie = function( key:string, value:string, expires?:any) {
    // 默认失效时间6小时
    expires = expires || 6/24;
    cookies.set(key, value, { expires: expires});
};
// 删除
const delCookie = function(key: string) {
    cookies.remove(key);
}

export { getCookie, setCookie, delCookie };

 