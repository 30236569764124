// import { ElLoading as Loading } from 'element-plus';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


NProgress.configure({ showSpinner: false })
let loadingCount = 0;

// let loading;
// const startLoading = () => {
//   loading = Loading.service({
//     fullscreen: true,
//     lock: true,
//     text: '数据加载中...',
//     spinner: 'el-icon-loading',
//     background: 'rgba(255, 255, 255, 0.3)',
//     customClass: 'loading',
//   });
// };
// const endLoading = () => {
//   loading.close();
// };

const startLoading = () => {
  NProgress.start();
};
const endLoading = () => {
  NProgress.done();
};


export const showLoading = () => {
  if (loadingCount === 0) {
    startLoading();
  }
  loadingCount += 1;
};

export const hideLoading = () => {
  setTimeout(() => {
    if (loadingCount <= 0) {
      return;
    }
    loadingCount -= 1;
    if (loadingCount === 0) {
      endLoading();
    }
  }, 300);
};
