import axios from '@/utils/axios';
import { ElMessage } from 'element-plus';
import { setCookie, getCookie } from '@/utils/cookie';
import router from '@/router'

const state = {
  userInfo: {
    user_name: "",
    type: "",
    token: "",
  }
};
const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    setCookie('token', userInfo.token);
    state.userInfo = userInfo;
  }
};
const actions = {
  // 用户登录
  async userLogin({ commit }, params = {}) {
    const { data } = await axios({
      url: '/api/user/login',
      method: 'post',
      data: params,
    });
    commit('SET_USER_INFO', data);
    router.push({path: '/manage'});
  },
  // 验证登录
  async checkLogin({ commit }) {
    const token = getCookie('token');
    if (!token) {
      router.push({name: 'login'});
      return;
    }
    let { data } = await axios({
      url: '/api/user/login',
      method: 'post',
      data: { token },
    });
    commit('SET_USER_INFO', data);
  },
  // 用户注册
  async userReg({}, params:any) {
    await axios({
      url: '/api/user/register',
      method: 'post',
      data: params,
    });
  },
  // 用户列表
  async userList({ commit }, params:any) {
    const { data = [] } = await axios({
      url: '/api/user/query_list',
      method: 'get',
      params
    });
    return data;
  },
  // 删除用户
  async userDetele({ commit }, params:any) {
    const result = await axios({
      url: '/api/user/delete',
      method: 'post',
      data: params,
    });
    return result;
  }, 
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
  