export const SITE_NAME = "山东渤海智慧冷库节能监督平台";

export const USER_TYPES = {
  1: {
    label: '管理员',
    value: 1,
    IsManage: true,
  },
  2: {
    label: '普通用户',
    value: 2,
    isReg: true,
    IsManage: true,
  }
}

// 正则验证表达式

export const REG_EXP = {
  phone: /^[1][3,4,5,7,8][0-9]{9}$/,
  idCard: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
}
