import { createStore } from 'vuex';
import * as getters from './getters';
import * as actions from './actions';
import user from "./modules/user";

/**
 * Vuex Store对象
 * @type {object}
 */
export default createStore({
  state: {},
  getters,
  actions,
  modules: {
    user
  },
});
