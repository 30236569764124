import { USER_TYPES } from "@/config/index"

export function userToken(state:any) {
    return state.user.token;
}
export function userType(state:any) {
    if (state.user.type === "admin") {
        return USER_TYPES[1].value;
    }
    return USER_TYPES[2].value;
}
export function userInfo(state:any) {
    return state.user.userInfo;
}